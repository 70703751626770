import FeatureGates from '@atlaskit/feature-gate-js-client';

export enum EndOfPageRecContainerCohort {
	CONTROL = 'control',
	GRID = 'grid',
	CAROUSEL = 'carousel',
}

const EXPERIMENT_NAME = 'confluence_end_of_page_recs_container';
const COHORT = 'cohort';

export const useWhichEndOfPageRecContainerToRender = (): EndOfPageRecContainerCohort => {
	return FeatureGates.getExperimentValue<EndOfPageRecContainerCohort>(
		EXPERIMENT_NAME,
		COHORT,
		EndOfPageRecContainerCohort.CONTROL,
	);
};
